import React from "react";
import Button from "../../shared/button";

export default function Links(props) {
    return (
        <section  id="time">
            <div className="grid grid-gap-0 content pxb-standard">
                    <div className="col-12 headline-1 fw-semi-bold py-grid-standard">Links &amp; Ressourcen</div>
                    <div className="col-12 md:col-4 xl:col-4 xs:pt-30 md:pt-0">
                        <Button
                            gridTemplateAreas={`'. . .' '. icon text'`}
                            iconColor="black"
                            text="Github Source"
                            textBackgroundColor="blue"
                            textColor="black"
                            link={props.links.github}
                            target="_blank"
                        />
                    </div>
                    <div className="col-12 md:col-4 xl:col-4">
                        <Button
                            gridTemplateAreas={`'. . .''. icon text' `}
                            iconColor="black"
                            text="Figma Prototyp"
                            textBackgroundColor="yellow"
                            textColor="black"
                            link={props.links.figma}
                            target="_blank"
                        />
                    </div>
                    <div className="col-12 md:col-4 xl:col-4">
                        <Button
                            gridTemplateAreas={`'. . .''. icon text'`}
                            iconColor="black"
                            text="Produkt Demo Video"
                            textBackgroundColor="red"
                            textColor="black"
                            link={props.links.demo}
                            target="_blank"
                        />
                    </div>
            </div>
        </section>
    );
}
