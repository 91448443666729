import React from "react";
import ColumnText from "../../shared/columnText";

export default function Text(props) {
  return (
    <section  id="time">
      <ColumnText
        nrColumns="2"
        bigTitle={
          <>
            {props.title}
          </>
        }
        textLeftStyle="body-1"
        listLeft={true}
        textLeft={
          <>
            {props.textLeft.map(element => <div><br />{element}<br /></div>)}
          </>
        }
        textRightStyle="body-1"
        listRight={true}
        textRight={
          <>
            {props.textRight.map(element => <div><br />{element}<br /></div>)}
          </>
        }
      />
    </section>
  );
}
