import React from "react";

export default function ProcessImage(props) {
    return (
        <div className="py-grid-standard">
            <img style={{width: "100%"}}
                src={props.image}
                alt={props.alt}
            />
        </div>
    )
}