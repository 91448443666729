import React from "react";
import SharedHeader from "../../shared/header";

export default function Header(props) {
  return (
    <section  id="header">
      <SharedHeader
        align="left"
        classNameImage="header-image"
        titlesize="display-2"
        titlecolor="blue"
        title={<>{props.title}</>}
        text={props.text}
        textsize="body-3"
        imageAltText={props.handle}
        image={props.image}
        projectLogo={props.projectLogo}
        ministryLogo={props.ministryLogo}
      />
    </section>
  );
}
