import React from "react";
import VerticalTile from "../../shared/verticalTile";
import { Problem, User } from "../../../assets/img/icons";

function Tile(props) {
  return (
    <div className="col-12 md:col-6 xl:col-6">
      <VerticalTile
        component={<img width="80" height="80" src={props.icon} alt="symbol"/>}
        title={props.title}
        excerpt={{
          content: props.text.map(element => <div><br/>{element}<br/></div>),
        }}
      />
    </div>
  )
}

export default function Challenge(props) {
  return (
    <section  id="facts">
      <div className="grid p-grid-standard grid-gap-0 content">
        <Tile icon={Problem} text={props.challenge} title="Hintergrund &amp; Herausforderung" />
        <Tile icon={User} text={props.solution} title="Zielgruppe &amp; Lösung" />
      </div>
    </section>
  );
}
