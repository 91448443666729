import React from "react";
import Card from "../../shared/card";

export default function Team(props) {
    return (
        <section  id="time">
            <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content">
                <div className="md:col-3 xs:col-12 headline-1 fw-semi-bold tc-blue">
                    <div className="pb-50">Das Team:</div>
                    <img
                        className="img-w-150 pl-5"
                        src={props.projectLogo}
                        alt="teilnehmer"
                    />
                </div>
                <div className="md:col-9 xs:col-12 grid grid-gap-0">
                    {props.members.map((member, index) => (
                        <Card  key={index} member={member} />
                    ))}
                </div>
            </div>
        </section>
    );
}
